<div class="component component-container"
     fxLayout="column"
     (keydown)="keyHandler.TriggerOnKeyDown($event)"
     tabindex="0">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1 *ngIf="mediaLayout" localize="medialayouts.single.header.edit"></h1>
  <h1 *ngIf="!mediaLayout" localize="medialayouts.single.header.new"></h1>

  <mat-toolbar>
    <span class="toolbar-spacer"></span>
    <button mat-button localize="title:common.save.title" (click)="save()">
      <icon icon="Save"></icon>
    </button>
    <button mat-button localize="title:common.back.msg" (click)="triggerOnClose()">
      <icon icon="Times"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container">

    <p *ngIf="!mediaLayout"><em localize="common.loadingdata.msg"></em></p>

    <div fxFlex="500px" class="b-form-container wrapper">

      <mat-form-field>
        <input matInput [placeholder]="textName" [(ngModel)]="mediaLayout.name">
      </mat-form-field>

      <mat-form-field>
        <mat-select [placeholder]="textGridSize" [(ngModel)]="mediaLayout.gridSize">
          <mat-option *ngFor="let gs of gridSizes" [value]="gs.value">{{gs.label}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <input matInput [placeholder]="textColumn1Width" [(ngModel)]="mediaLayout.column1Width" numericInput>
      </mat-form-field>

      <mat-form-field>
        <input matInput [placeholder]="textColumn2Width" [(ngModel)]="mediaLayout.column2Width" numericInput>
      </mat-form-field>

      <mat-form-field>
        <input matInput [placeholder]="textRow1Height" [(ngModel)]="mediaLayout.row1Height" numericInput>
      </mat-form-field>

      <mat-form-field>
        <input matInput [placeholder]="textRow2Height" [(ngModel)]="mediaLayout.row2Height" numericInput>
      </mat-form-field>

      <mat-form-field>
        <input matInput [placeholder]="textCellPadding" [(ngModel)]="mediaLayout.cellPadding" numericInput>
      </mat-form-field>

      <mat-form-field>
        <mat-select [placeholder]="textTransitionMode" [(ngModel)]="mediaLayout.transitionMode">
          <mat-option value="None">None</mat-option>
          <mat-option value="Fade">Fade</mat-option>
          <mat-option value="SlideLeft">Slide Left</mat-option>
          <mat-option value="SlideZoomLeft">Slide Zoom Left</mat-option>
          <mat-option value="SlideUp">Slide Up</mat-option>
          <mat-option value="SwipeRight">Swipe Right</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <input matInput [placeholder]="textTransitionDuration" [(ngModel)]="mediaLayout.transitionDuration" numericInput>
      </mat-form-field>

      <mat-form-field>
        <input matInput [placeholder]="textBrowserZoom" [(ngModel)]="mediaLayout.browserZoom">
      </mat-form-field>

      <div class="b-form-container">
        <mat-checkbox [(ngModel)]="mediaLayout.pane3OnTop"><span localize="medialayouts.single.form.pane3ontop.title"></span></mat-checkbox>
      </div>

      <mat-form-field *ngIf="[1, 2, 3, 4].indexOf(mediaLayout.gridSize)>=0">
        <mat-select [placeholder]="textMediaDeviceId01" [(ngModel)]="mediaLayout.mediaDeviceId01">
          <mat-optgroup [label]="textDeviceDevice">
            <mat-option *ngFor="let d of devices" [value]="d.mediaDeviceID">{{ d.deviceName }}</mat-option>
          </mat-optgroup>
          <mat-optgroup [label]="textDeviceGroup">
            <mat-option *ngFor="let g of groups" [value]="g.mediaDeviceID">{{ g.deviceName }}</mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="[2, 3, 4].indexOf(mediaLayout.gridSize)>=0">
        <mat-select [placeholder]="textMediaDeviceId02" [(ngModel)]="mediaLayout.mediaDeviceId02">
          <mat-optgroup [label]="textDeviceDevice">
            <mat-option *ngFor="let d of devices" [value]="d.mediaDeviceID">{{ d.deviceName }}</mat-option>
          </mat-optgroup>
          <mat-optgroup [label]="textDeviceGroup">
            <mat-option *ngFor="let g of groups" [value]="g.mediaDeviceID">{{ g.deviceName }}</mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="[3, 4].indexOf(mediaLayout.gridSize)>=0">
        <mat-select [placeholder]="textMediaDeviceId03" [(ngModel)]="mediaLayout.mediaDeviceId03">
          <mat-optgroup [label]="textDeviceDevice">
            <mat-option *ngFor="let d of devices" [value]="d.mediaDeviceID">{{ d.deviceName }}</mat-option>
          </mat-optgroup>
          <mat-optgroup [label]="textDeviceGroup">
            <mat-option *ngFor="let g of groups" [value]="g.mediaDeviceID">{{ g.deviceName }}</mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="[4].indexOf(mediaLayout.gridSize)>=0">
        <mat-select [placeholder]="textMediaDeviceId04" [(ngModel)]="mediaLayout.mediaDeviceId04">
          <mat-optgroup [label]="textDeviceDevice">
            <mat-option *ngFor="let d of devices" [value]="d.mediaDeviceID">{{ d.deviceName }}</mat-option>
          </mat-optgroup>
          <mat-optgroup [label]="textDeviceGroup">
            <mat-option *ngFor="let g of groups" [value]="g.mediaDeviceID">{{ g.deviceName }}</mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>

    </div>

  </div>

</div>
